import React, { useEffect, useState } from "react";
import CheckoutForm from "./CheckOutForm";
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2'
import {Elements, PaymentElement} from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import PageContainer from "Components/Global/PageContainer";
import "Styles/Packages/PackagesContainer.scss";
import { IonSkeletonText } from "@ionic/react";
import PackagePreviewCard from "Components/Global/PackagePreviewCard";
import { load_products } from "Store/products/thinks";
import { mark_project_paid } from "Store/new_project/thinks";
import {
  get_data_and_listen_for_changes
} from "Store/projects/thinks";
import {
  PARSE_SERVER_URL,
  PARSE_APP_ID,
  PARSE_JS_ID,
  STRIPE_PUBLISHABLE_KEY,
} from "Constants";
import {loadStripe} from '@stripe/stripe-js';
const StripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const Payments = () => {


  const location = useLocation();

  useEffect(() => {
    //console.log("hello9");
    if (!location.state || !location.state.selected_package) return;
    console.log(location.state.selected_package);

    const selectedId = `${location.state.selected_package.included_consultations_count}c_consultation`;
    
    console.log("the selected id is :" + selectedId);
    
    //InAppPurchase2.verbosity = InAppPurchase2.DEBUG;
    //console.debug("testz");
    InAppPurchase2.register([{
      id: "1c_consultation",
      type: InAppPurchase2.CONSUMABLE,
    },{
      id: "5c_consultation",
      type: InAppPurchase2.CONSUMABLE,
    },{
      id: "10c_consultation",
      type: InAppPurchase2.CONSUMABLE,
    },{
      id: "20c_consultation",
      type: InAppPurchase2.CONSUMABLE,
    }]);
    //console.log(InAppPurchase2.when("1_consultation"));
    InAppPurchase2.when("products").registered( (product) => {
      //console.log('Registered: ' + JSON.stringify(product));
    });
   
    

    // Updated
    InAppPurchase2.when(selectedId).updated( (product) => {
      //console.log('Updated' + JSON.stringify(product));
      if (product.owned){
       console.warn("It was owned");
      }
      if (product.state === InAppPurchase2.APPROVED){

        // console.log("going to finish");
        // console.log(product);
        //   dispatch(mark_project_paid(location.state?.project_objectId)).then((result) => {
        //   console.warn(result);
        //   set_is_success(result.is_success);
    
        //   dispatch(get_data_and_listen_for_changes()).then(re=>{
        //     console.warn("result:" + result.is_success);
        //     if (result.is_success) {
        //       product.finish();
        //       console.warn("finished ran");
        //       history.replace(redirect_url);
                   
        //     }
        //   });
        // });

      }
    });

    InAppPurchase2.when(selectedId).approved( (r) => {
      console.log('approved!' + JSON.stringify(r));
           dispatch(mark_project_paid(location.state?.project_objectId)).then((result) => {
          console.warn(result);
          set_is_success(result.is_success);
    
          dispatch(get_data_and_listen_for_changes()).then(re=>{
            console.warn("result:" + result.is_success);
            if (result.is_success) {
              r.finish();
              console.warn("finished ran");
              history.replace(redirect_url);
                   
            }
          });
        });
     
    });
   
    InAppPurchase2.when(selectedId).verified( (p) => {
      console.log('verified' + JSON.stringify(p));
      
    });
    // User closed the native purchase dialog
    InAppPurchase2.when(selectedId).cancelled( (product) => {
        console.error('Purchase was Cancelled');
    });
   
    // Track all store errors
    InAppPurchase2.error( (err) => {
      console.error('Store Error ' + JSON.stringify(err));
    });
   
    // Run some code only when the store is ready to be used
    InAppPurchase2.ready(() =>  {
      console.log('Store is ready');
      console.log('Products: ' + JSON.stringify(InAppPurchase2.products));
      console.log(JSON.stringify(InAppPurchase2.get(selectedId)));
    });
    InAppPurchase2.refresh();
   // console.log("order it");
    //InAppPurchase2.order("1_consultation");
    
  },[location.state?.selected_package]);
  //const { products } = useSelector((state) => state);
  //const { data, busy } = products;
  const [is_success, set_is_success] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const redirect_url = `/add_details/${location.state?.project_objectId}?is_new_project=true`;

  //const { package_objectId } = qs.parse(location.search);
  if (!location.state) return <PageContainer id="payments" pageContainerClassName="payments"></PageContainer>;
  console.error(location);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: location.state?.clientSecret.clientSecret,
  };

  const paymentSucceeded = ()=>{
      dispatch(mark_project_paid(location.state?.project_objectId)).then((result) => {
        set_is_success(result.is_success);
  
        dispatch(get_data_and_listen_for_changes()).then(r=>{
          if (result.is_success) {
            history.replace(redirect_url);
          }
        });
      });
  }
  const purchaseHandler =()=>{
    const selectedId = `${location.state.selected_package.included_consultations_count}c_consultation`;
    
    console.info("going to purchase");
    InAppPurchase2.order(selectedId).then(r=>{
      console.log("purchase complete");
      //console.log(r);

      // dispatch(mark_project_paid(location.state?.project_objectId)).then((result) => {
      //   set_is_success(result.is_success);
  
      //   dispatch(get_data_and_listen_for_changes()).then(r=>{
      //     if (result.is_success) {
      //       history.replace(redirect_url);
      //     }
      //   });
      // });
      
    }).error(e=>{
      console.log("was an error");
      console.log(e);
    });
    
  }

  return (
    
    <PageContainer id="payments" pageContainerClassName="payments">
      <PackagePreviewCard package_data={location.state.selected_package} ></PackagePreviewCard>
      {/* <Elements stripe={StripePromise} options={options}>
      <CheckoutForm paymentSucceeded={paymentSucceeded} />
    </Elements> */}
     <ion-button onClick={purchaseHandler} color="success" expand="full">Pay now</ion-button>
     
    </PageContainer>
  );


  
};



export default Payments;
